const Data = [
  {
    title: "23. Sistema de Anotações",
    date: "dezembro de 2024",
    description: "• Projetinho simples feito durante a ultima aula de JS do semestre :]",
    technology: "HTML, CSS, JS, NodeJS, Express",
    link: "https://github.com/codinGloria/sistemaAnotacoes"
  },
  {
    title: "22. Portfólio Simples",
    date: "julho de 2024",
    description: "• Projeto feito como avaliação final da disciplina Prog. Web com HTML5 e CSS3",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/portfolioHTMLCSS"
  },
  {
    title: "21. Eclipse Hotel",
    date: "julho de 2024",
    description: "• Sistema de gestão de hotel, desenvolvido para um teste técnico.",
    technology: "Java, Spring Boot, PostgreSQL"
  },
  {
    title: "20. Plann.er",
    date: "julho de 2024",
    description: "• O projeto tem como objetivo ajudar o usuário a organizar viagens, programar atividades, convidar pessoas.",
    technology: "Java, React",
    link: "https://github.com/codinGloria/nlwJourney-planner"
  },
  {
    title: "19. Portfólio",
    date: "julho de 2024",
    description: "• Este aqui que você está vendo :]",
    technology: "React"
  },
  {
    title: "18. Agência de Viagens",
    date: "junho de 2024",
    description: "• API RESTful para uma agência de viagens.",
    technology: "Java, Spring Boot",
    link: "https://github.com/codinGloria/CRUDagenciaViagens"
  },
  {
    title: "17. Healing",
    date: "abril de 2024",
    description: "• Sistema para médicos e pacientes com funcionalidades de agendamento e gestão de consultas.",
    technology: "Python, Django",
    link: "https://github.com/codinGloria/psw10-Healing"
  },
  {
    title: "16. PassIn",
    date: "abril de 2024",
    description: "• Plataforma para gerenciamento de eventos presenciais, permitindo criação e check-in de eventos.",
    technology: "Java, React",
    link: "https://github.com/codinGloria/nlwUnite-PassIn"
  },
  {
    title: "15. TimeWise",
    date: "janeiro de 2024",
    description: "• Sistema full stack para gerenciamento de tarefas e controle de gastos.",
    technology: "Java, Angular",
    link: "https://github.com/codinGloria/projectTimeWise"
  },
  {
    title: "14. Banco de Talentos",
    date: "novembro de 2023",
    description: "• CRUD simples de um sistema de armazenamento de dados.",
    technology: "Java, JPA e Hibernate",
    link: "https://github.com/codinGloria/academiaJava/tree/main/aula28/Labs"
  },
  {
    title: "13. Gerenciamento de Estoque",
    date: "novembro de 2023",
    description: "• Sistema para gerenciamento de estoque de lojas, com interface gráfica.",
    technology: "Java, JavaFX",
    link: "https://github.com/codinGloria/gerenciamentoEstoque"
  },
  {
    title: "12. Página Inicial",
    date: "novembro de 2023",
    description: "• Página inicial de um site com tema One Piece.",
    technology: "HTML, CSS, JS",
    link: "https://mini-site-liviabraz.vercel.app/"
  },
  {
    title: "11. Jogo da Velha",
    date: "novembro de 2023",
    description: "• O joguinho que todos conhecemos bem...",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/jogoDaVelha"
  },
  {
    title: "10. Gerenciamento de Biblioteca",
    date: "outubro de 2023",
    description: "• Sistema de gerenciamento com biblioteca para interface gráfica.",
    technology: "Java, JOption",
    link: "https://github.com/codinGloria/academiaJava/tree/main/aula06/labs/biblioteca"
  },
  {
    title: "9. Gerenciamento de PetShop",
    date: "outubro de 2023",
    description: "• Sistema de gerenciamento com biblioteca para interface gráfica.",
    technology: "Java, JOption",
    link: "https://github.com/codinGloria/academiaJava/tree/main/aula07/labs/PetShop"
  },
  {
    title: "8. Jogo de Xadrez",
    date: "janeiro de 2023",
    description: "• Jogo de xadrez desenvolvido para melhorar os conceitos de programação orientada a objetos.",
    technology: "Java",
    link: "https://github.com/codinGloria/cursoJavaUdemy/tree/main/Se%C3%A7%C3%A3o%2016%20-%20PROJETO%20-%20Sistemas%20jogo%20de%20xadrez/src"
  },
  {
    title: "7. Portfólio",
    date: "março de 2022",
    description: "• Página de portfólio criada durante a Imersão DEV.",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/imersaoDevAlura"
  },
  {
    title: "6. Super Trunfo (Genshin Impact)",
    date: "março de 2022",
    description: "• Jogo de Super Trunfo usando personagens de Genshin Impact, dessa vez com mais funcionalidades e estilização",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/superTrunfoGenshin"
  },
  {
    title: "5. Super Trunfo (Hololive EN)",
    date: "março de 2022",
    description: "• Jogo de Super Trunfo simples, usando personagens do grupo Hololive EN",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/imersaoDevAlura"
  },
  {
    title: "4. Tabela de Classificação",
    date: "março de 2022",
    description: "• Página simples para exibir e gerenciar tabelas de classificação",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/imersaoDevAlura"
  },
  {
    title: "3. O Mentalista",
    date: "março de 2022",
    description: "• Aplicação de adivinhação de números.",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/imersaoDevAlura"
  },
  {
    title: "2. AluraFlix",
    date: "março de 2022",
    description: "• Projeto feito na Imersão DEV, onde criamos uma aplicação para listar filmes ou séries.",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/aluraFlix"
  },
  {
    title: "1. Conversor de Moedas",
    date: "março de 2022",
    description: "• Um conversor de moedas simples.",
    technology: "HTML, CSS, JS",
    link: "https://github.com/codinGloria/imersaoDevAlura"
  }
];

export default Data;